import {
  RegistryTenant,
  RegistryTenantInitialFormat,
  TenantBrandRegistry,
  TenantId,
} from '@adecco/tenant-brand-registry';
import {IGetListParams} from '../IGetListParams';
import {sort} from '../utils/sorting';

export class SimpleTenantsDataProvider {
  private readonly tenantBrandRegistry = new TenantBrandRegistry();

  public getAllSimpleTenants(params?: IGetListParams): Readonly<RegistryTenant[]> {
    const allTenants = this.tenantBrandRegistry.getAllTenants({includeRootTenantId: true});
    const simpleTenants = allTenants
      .filter((tenant) => tenant.id in TenantId && !tenant.brand?.isFakeBrand)
      .map((tenant) => ({...tenant, displayName: tenant.brand?.canonicalName || ''}));
    return sort(params, [...simpleTenants]);
  }

  public getAllSimpleTenantsJson(): Readonly<RegistryTenantInitialFormat[]> {
    const allTenants = this.tenantBrandRegistry.getAllTenantsInInitialFormat();
    return allTenants.filter((tenant) => tenant.id in TenantId);
  }
}
